import { useOnboardingNodeData } from "@/lib/hooks";
import { Spinner } from "@radix-ui/themes";
import moment from "moment";
import {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";

export type ChatMessages =
  | "goal"
  | "timeline"
  | "sections"
  | "autosave"
  | "travel-plan"
  | "travel-details"
  | "can-be-responsive"
  | "date-range"
  | "case-strategy"
  | "documents";

const baseOrder: ChatMessages[] = [
  "goal",
  "timeline",
  "sections",
  "autosave",
  "travel-plan",
  "can-be-responsive",
  "case-strategy",
  "documents",
] as const;

export type OnboardingState = {
  messages: ChatMessages[];
  ui: {
    disableScroll: boolean;
    isProgressing: boolean;
    isTyping: boolean;
  };
  data: {
    travelWithin2Weeks?: boolean;
    travelDetails?: string;
    canBeResponsive?: boolean;
    bestDates?: {
      startDate?: string;
      endDate?: string;
    };
  };
  currentStep: ChatMessages;
};

type OnboardingAction =
  | { type: "SET_TRAVEL_WITHIN_WEEKS"; value: boolean }
  | { type: "LOAD_TRAVEL_WITHIN_WEEKS"; value: boolean }
  | { type: "SET_TRAVEL_DETAILS"; value: string }
  | { type: "LOAD_TRAVEL_DETAILS"; value: string }
  | { type: "SET_CAN_BE_RESPONSIVE"; value: boolean }
  | { type: "LOAD_CAN_BE_RESPONSIVE"; value: boolean }
  | { type: "SET_BEST_DATES"; value: { startDate?: string; endDate?: string } }
  | { type: "LOAD_BEST_DATES"; value: { startDate?: string; endDate?: string } }
  | { type: "SET_SCROLL_DISABLED"; value: boolean }
  | { type: "START_PROGRESS" }
  | { type: "FINISH_PROGRESS" }
  | { type: "NEXT_MESSAGE" }
  | { type: "FILTER_MESSAGE"; message: ChatMessages };

const isDateRangeValid = (startDate: string, endDate: string): boolean => {
  const start = moment(startDate);
  const end = moment(endDate);
  const twoWeeks = moment(start).add(2, "weeks");
  return end.isAfter(start) && end.isAfter(twoWeeks);
};

const validateTransition = (state: OnboardingState): boolean => {
  const { currentStep, data } = state;

  if (currentStep === "travel-plan") {
    if (data.travelWithin2Weeks === undefined) return false;
    if (
      data.travelWithin2Weeks &&
      (data.travelDetails == null || data.travelDetails.trim().length === 0)
    ) {
      return false;
    }
  }

  if (currentStep === "can-be-responsive") {
    if (data.canBeResponsive === undefined) return false;
    if (!data.canBeResponsive) {
      if (!data.bestDates?.startDate || !data.bestDates?.endDate) return false;
      if (!isDateRangeValid(data.bestDates.startDate, data.bestDates.endDate))
        return false;
    }
  }

  return true;
};

const getNextStep = (currentStep: ChatMessages): ChatMessages | null => {
  const currentIndex = baseOrder.indexOf(currentStep);

  if (currentIndex === -1) {
    if (currentStep === "travel-details") return "can-be-responsive";
    if (currentStep === "date-range") return "case-strategy";
  }

  return currentIndex < baseOrder.length - 1
    ? baseOrder[currentIndex + 1]
    : null;
};

const onboardingReducer = (
  state: OnboardingState,
  action: OnboardingAction
): OnboardingState => {
  switch (action.type) {
    case "SET_TRAVEL_WITHIN_WEEKS":
      return {
        ...state,
        data: { ...state.data, travelWithin2Weeks: action.value },
        messages: action.value
          ? [
              ...state.messages.filter(
                (m) => m !== "can-be-responsive" && m !== "date-range"
              ),
              "travel-details",
            ]
          : [
              ...state.messages.filter((m) => m !== "travel-details"),
              "can-be-responsive",
            ],
        currentStep: action.value ? "travel-details" : "can-be-responsive",
      };

    case "LOAD_TRAVEL_WITHIN_WEEKS":
      return {
        ...state,
        data: { ...state.data, travelWithin2Weeks: action.value },
      };

    case "SET_TRAVEL_DETAILS":
      return {
        ...state,
        data: { ...state.data, travelDetails: action.value },
        messages: [...state.messages],
        currentStep: "travel-details",
      };

    case "LOAD_TRAVEL_DETAILS":
      return {
        ...state,
        data: { ...state.data, travelDetails: action.value },
      };

    case "SET_CAN_BE_RESPONSIVE":
      return {
        ...state,
        data: { ...state.data, canBeResponsive: action.value },
        messages: action.value
          ? [
              ...state.messages.filter((m) => m !== "date-range"),
              "case-strategy",
            ]
          : [
              ...state.messages.filter((m) => m !== "case-strategy"),
              "date-range",
            ],
        currentStep: action.value ? "case-strategy" : "date-range",
      };

    case "LOAD_CAN_BE_RESPONSIVE":
      return {
        ...state,
        data: { ...state.data, canBeResponsive: action.value },
      };

    case "SET_BEST_DATES":
      return {
        ...state,
        data: { ...state.data, bestDates: action.value },
      };

    case "LOAD_BEST_DATES":
      return {
        ...state,
        data: { ...state.data, bestDates: action.value },
      };

    case "SET_SCROLL_DISABLED":
      return {
        ...state,
        ui: { ...state.ui, disableScroll: action.value },
      };

    case "START_PROGRESS":
      return {
        ...state,
        ui: { ...state.ui, isProgressing: true, isTyping: true },
      };

    case "FINISH_PROGRESS":
      return {
        ...state,
        ui: { ...state.ui, isProgressing: false, isTyping: false },
      };

    case "NEXT_MESSAGE":
      if (!validateTransition(state)) return state;

      const nextStep = getNextStep(state.currentStep);
      if (!nextStep) return state;

      return {
        ...state,
        messages: [...state.messages, nextStep],
        currentStep: nextStep,
      };

    case "FILTER_MESSAGE":
      return {
        ...state,
        messages: state.messages.filter((msg) => msg !== action.message),
      };

    default:
      return state;
  }
};

const initialState: OnboardingState = {
  messages: ["goal"],
  ui: {
    disableScroll: false,
    isProgressing: false,
    isTyping: false,
  },
  data: {},
  currentStep: "goal",
};

export const O1StartContext = createContext<{
  state: OnboardingState;
  dispatch: React.Dispatch<OnboardingAction>;
  nextMessage: () => void;
  setTravelWithin2Weeks: (value: boolean) => Promise<void>;
  setTravelDetails: (value: string) => Promise<void>;
  setCanBeResponsive: (value: boolean) => Promise<void>;
  setBestDates: (value: {
    startDate?: string;
    endDate?: string;
  }) => Promise<void>;
  setDisableScroll: (value: boolean) => void;
} | null>(null);

export const O1StartProvider = (props: {
  children: React.ReactNode;
  onboardingNodeId: number;
}) => {
  const { onboardingNodeId } = props;
  const [state, dispatch] = useReducer(onboardingReducer, initialState);

  const {
    data: serverData,
    fetching,
    error,
    doSetData: doChangeData,
  } = useOnboardingNodeData(onboardingNodeId ?? -1);

  useEffect(() => {
    if (!serverData) return;
    if (serverData.travelWithin2Weeks?.value !== undefined) {
      dispatch({
        type: "LOAD_TRAVEL_WITHIN_WEEKS",
        value: serverData.travelWithin2Weeks.value as boolean,
      });
    }

    if (serverData.travelDetails?.value !== undefined) {
      dispatch({
        type: "LOAD_TRAVEL_DETAILS",
        value: serverData.travelDetails.value as string,
      });
    }

    if (serverData.canBeResponsive?.value !== undefined) {
      dispatch({
        type: "LOAD_CAN_BE_RESPONSIVE",
        value: serverData.canBeResponsive.value as boolean,
      });
    }

    if (serverData.bestDates?.value) {
      dispatch({
        type: "LOAD_BEST_DATES",
        value: serverData.bestDates.value as {
          startDate?: string;
          endDate?: string;
        },
      });
    }
  }, [serverData]);

  const nextMessage = useCallback(() => {
    if (state.ui.isProgressing) return;

    const currentMessage = state.messages[state.messages.length - 1];
    if (!currentMessage) return;

    if (
      (currentMessage === "travel-plan" &&
        state.data.travelWithin2Weeks === undefined) ||
      (currentMessage === "can-be-responsive" &&
        state.data.canBeResponsive === undefined) ||
      (currentMessage === "travel-details" &&
        (!state.data.travelDetails ||
          state.data.travelDetails.trim().length === 0))
    ) {
      return;
    }

    dispatch({ type: "START_PROGRESS" });
    setTimeout(() => {
      dispatch({ type: "NEXT_MESSAGE" });
      dispatch({ type: "FINISH_PROGRESS" });
    }, 1500);
  }, [state.messages, state.data]);

  const setTravelWithin2Weeks = useCallback(
    async (value: boolean) => {
      await doChangeData("travelWithin2Weeks", value);
      dispatch({ type: "SET_TRAVEL_WITHIN_WEEKS", value });
    },
    [doChangeData]
  );

  const setTravelDetails = useCallback(
    async (value: string) => {
      await doChangeData("travelDetails", value);
      dispatch({ type: "SET_TRAVEL_DETAILS", value });
    },
    [doChangeData]
  );

  const setCanBeResponsive = useCallback(
    async (value: boolean) => {
      await doChangeData("canBeResponsive", value);
      dispatch({ type: "SET_CAN_BE_RESPONSIVE", value });
    },
    [doChangeData]
  );

  const setBestDates = useCallback(
    async (value: { startDate?: string; endDate?: string }) => {
      await doChangeData("bestDates", value);
      dispatch({ type: "SET_BEST_DATES", value });
    },
    [doChangeData]
  );

  const setDisableScroll = useCallback((value: boolean) => {
    dispatch({ type: "SET_SCROLL_DISABLED", value });
  }, []);

  return (
    <O1StartContext.Provider
      value={{
        state,
        dispatch,
        nextMessage,
        setTravelWithin2Weeks,
        setTravelDetails,
        setCanBeResponsive,
        setBestDates,
        setDisableScroll,
      }}
    >
      {error != null && serverData == null && (
        <div className="text-sm text-red-500">
          Error loading onboarding node
        </div>
      )}
      {fetching && serverData == null && (
        <div className="flex flex-col gap-4 items-center justify-center w-full h-full">
          <Spinner />
        </div>
      )}
      {serverData != null && props.children}
    </O1StartContext.Provider>
  );
};

export const useO1StartContext = () => {
  const context = useContext(O1StartContext);
  if (!context) throw new Error("Missing O1StartContext.Provider in the tree");
  return context;
};
