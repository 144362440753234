import { useWorkspaces } from "@/lib/hooks";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useState } from "react";
import { cn } from "@/lib/cn";
import { ChevronDownIcon } from "@radix-ui/themes";
import { useAuth, useUser } from "@clerk/clerk-react";
import { ManageTeamMembersModal } from "./manageTeamMembersModal";

export const WorkplaceSelectorExpanded = () => {
  const { selectedWorkspace, setSelectedWorkspace, workspaces } =
    useWorkspaces();

  const { signOut } = useAuth();
  const { user } = useUser();

  const [open, setOpen] = useState(false);
  const [manageTeamMembersModalOpen, setManageTeamMembersModalOpen] =
    useState(false);

  const email = user?.primaryEmailAddress?.emailAddress;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="flex flex-row gap-2 pl-3 py-3 items-center hover:bg-grey-700">
          <img
            src={`https://avatar.vercel.sh/${selectedWorkspace?.id}`}
            className="w-4 h-4 rounded-sm grayscale-[50%]"
          />
          <span className="text-sm font-medium text-grey-100">
            {selectedWorkspace?.name}
          </span>
          <ChevronDownIcon />
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        className="-translate-y-2 bg-grey-800 w-60 shadow-border p-0 rounded-md"
      >
        <h1 className="text-sm p-2 text-grey-300 items-center pl-3">
          {email ?? "Workspaces"}
        </h1>
        <div className="flex flex-col w-full border-b-[0.8px] border-b-grey-600 p-0.5">
          {workspaces.map((workspace) => (
            <button
              key={workspace.id}
              className={cn(
                "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                workspace.id === selectedWorkspace?.id &&
                  "bg-grey-700 text-grey-100"
              )}
              onClick={() =>
                setSelectedWorkspace({
                  ...workspace,
                  selectedOnboardingId:
                    workspace.onboardings.length > 0
                      ? workspace.onboardings[0].id
                      : undefined,
                })
              }
            >
              <img
                src={`https://avatar.vercel.sh/${workspace.id}`}
                className="w-4 h-4 rounded-sm grayscale-[50%]"
              />
              {workspace.name}
            </button>
          ))}
        </div>
        <div className="p-0.5">
          {selectedWorkspace?.id != null &&
            selectedWorkspace.id.startsWith("company") && (
              <>
                <ManageTeamMembersModal
                  open={manageTeamMembersModalOpen}
                  onOpenChange={setManageTeamMembersModalOpen}
                />
                <button
                  className={cn(
                    "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm"
                  )}
                  onClick={() => {
                    setManageTeamMembersModalOpen(true);
                  }}
                >
                  Manage team members
                </button>
              </>
            )}

          <button
            className={cn(
              "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm text-grey-300"
            )}
            onClick={() =>
              signOut({
                redirectUrl: "/",
              })
            }
          >
            Log out
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const WorkplaceSelectorCollapsed = () => {
  const { selectedWorkspace, setSelectedWorkspace, workspaces } =
    useWorkspaces();

  const { signOut } = useAuth();
  const { user } = useUser();

  const [open, setOpen] = useState(false);

  const email = user?.primaryEmailAddress?.emailAddress;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="w-full flex items-center justify-center p-3 hover:bg-grey-700">
          <img
            src={`https://avatar.vercel.sh/${selectedWorkspace?.id}`}
            className="w-4 h-4 rounded-sm grayscale-[50%]"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        className="-translate-y-2 bg-grey-800 w-60 shadow-border p-0 rounded-md"
      >
        <h1 className="text-sm p-2 text-grey-300 items-center pl-3">
          {email ?? "Workspaces"}
        </h1>
        <div className="flex flex-col w-full border-b-[0.8px] border-b-grey-600 p-0.5">
          {workspaces.map((workspace) => (
            <button
              key={workspace.id}
              className={cn(
                "flex flex-row w-full p-2 items-center gap-2 text-sm rounded-sm hover:bg-grey-700 truncate text-nowrap text-grey-200",
                workspace.id === selectedWorkspace?.id &&
                  "bg-grey-700 text-grey-100"
              )}
              onClick={() => setSelectedWorkspace(workspace)}
            >
              <img
                src={`https://avatar.vercel.sh/${workspace.id}`}
                className="w-4 h-4 rounded-sm grayscale-[50%]"
              />
              {workspace.name}
            </button>
          ))}
        </div>
        <div className="p-0.5">
          {selectedWorkspace?.id != null &&
            selectedWorkspace.id.startsWith("company") && (
              <button
                className={cn(
                  "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm"
                )}
              >
                Manage team members
              </button>
            )}

          <button
            className={cn(
              "flex flex-row w-full p-2 cursor-pointer items-center gap-2 text-sm hover:bg-grey-700 rounded-sm text-grey-300"
            )}
            onClick={() =>
              signOut({
                redirectUrl: "/",
              })
            }
          >
            Log out
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
