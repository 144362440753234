import { cn } from "@/lib/cn";

export const Progress = (props: { percent: number }) => (
  <div className="w-full shadow-border rounded-md h-[3px] max-w-full">
    <div
      className="bg-grey-200 h-[3px] rounded-md transition-all duration-300"
      style={{
        width: `${props.percent > 100 ? 100 : props.percent}%`,
      }}
    ></div>
  </div>
);

export const DashboardProgressBar = (props: {
  completed: number;
  total: number;
}) => (
  <div className="flex flex-row items-center w-[64px] gap-[2px] text-xs">
    <div
      style={{
        width: `${(props.completed / props.total) * 100}%`,
        height: "4px",
        borderRadius: "1px",
        display: `${props.completed === 0 ? "hidden" : "block"}`,
      }}
      className={cn("bg-critical", props.completed === props.total && "bg-positive")}
    />

    <div
      style={{
        width: `${((props.total - props.completed) / props.total) * 100}%`,
        height: "4px",
        borderRadius: "1px",
        display: `${props.total === props.completed ? "hidden" : "block"}`,
      }}
      className="bg-grey-600"
    />
  </div>
);
