import { CreateAdditionalWorkExperienceNodeOption } from "@/components/CreateAdditionalWorkExperienceNodeOption";
import { Field, OnboardingForm, SubkeyUploadArea } from "@/components/form";
import { DateInput, Input, TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const Award = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your work experience">
      <Field id="awardTitle" label="Award title">
        <Input
          type="text"
          placeholder="e.g. Forbes 30 under 30"
          value={data.awardTitle?.value as string}
          onValueChange={(val) => doSetData("awardTitle", val)}
        />
      </Field>

      <Field
        id="receivedDate"
        label={`When did you receive ${
          data.awardTitle?.value == null
            ? "this award"
            : `the ${data.awardTitle?.value} award`
        }?`}
      >
        <DateInput
          value={
            data.receivedDate?.value != null
              ? new Date(data.receivedDate.value)
              : undefined
          }
          onValueChange={(val) => doSetData("receivedDate", val?.toISOString())}
        />
      </Field>

      <Field
        id="supportingDocuments"
        label={`Upload confirmation of receiving ${
          data.awardTitle?.value == null
            ? "this award"
            : `the ${data.awardTitle?.value} award`
        }`}
      >
        <SubkeyUploadArea
          onboardingNodeId={currentOnboardingNodeId ?? -1}
          subKey="supportingDocuments"
        />
      </Field>

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
          charLimit={500}
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="award"
          name="award"
          description="TODO: add copy"
        />
      )}
    </OnboardingForm>
  );
};
