import { EditorContextProvider } from "@/providers/editorProvider";
import { createFileRoute } from "@tanstack/react-router";
import { TextEditor } from "@/components/textEditor";
import { useState } from "react";
import { Spinner } from "@radix-ui/themes";
import { Mark } from "@/components/mark";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { FaRegCircleCheck } from "react-icons/fa6";
import { LetterActionType } from "../review/$letterActionId";
import { customerApi } from "@lighthouse/api";
import {
  FailiureModal,
  InformationModal,
  ScrollDownPopup,
  SubmitFailedPopup,
} from "@/routes/_portal/letter/sign/$letterActionId";
import { ExternalLandingPage } from "@/components/externalLandingPage";
import { ExternalTokenExpiredPage } from "@/components/externalTokenExpiredPage";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { useQuery } from "@tanstack/react-query";
import { LetterActionNotFoundPage } from "@/components/letterActionNotFoundPage";

const SuccessModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Letter Signed!"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Your signature has been successfully added to the letter. The letter
          is now available to the applicant and the Lighthouse team. You can
          close this window.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

const LetterSignatureEditor = (props: {
  data: LetterActionType;
  hideInfoModal: boolean;
  hideInitialInfoModal: boolean;
}) => {
  const { data, hideInfoModal, hideInitialInfoModal } = props;

  const { token } = Route.useSearch();

  const [informationModalOpen, setInformationModalOpen] = useState(
    data.status === "sent" && !hideInitialInfoModal
  );
  const [status, setStatus] = useState(data.status);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const approve = async () => {
    setLoading(true);

    const res = await customerApi.submitLetterAction({
      letterActionId: data.id,
      token: token,
      action: "approve",
    });

    setLoading(false);

    if (!res.success) {
      setFailureModalOpen(true);
      return;
    }

    setSuccessModalOpen(true);
    setStatus("approved");
  };

  return (
    <div
      className="relative w-full h-full"
      style={{
        background: "url('/backgrounds/letter-review.webp')",
        backgroundBlendMode: "normal",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {!hideInfoModal && (
        <InformationModal
          isBeneficiary={false}
          beneficiaryName={data.beneficiaryName}
          open={informationModalOpen}
          setOpen={setInformationModalOpen}
        />
      )}
      <SuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
      <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />
      <LoadingOverlay isLoading={loading} />

      <div className="w-full h-full absolute bg-grey-800 bg-opacity-25 backdrop-blur-[2px]" />
      <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
        {data.letterId != null && (
          <EditorContextProvider
            letterId={data.letterId}
            userId={data.externalEmail}
            userName={data.externalName}
            commentUserIdWhitelist={[]}
            mode="sign"
            letterActionId={data.id}
            onSignatureAdded={approve}
            disableSignatures={status === "approved"}
          >
            {status === "sent" && <ScrollDownPopup />}

            <SubmitFailedPopup
              onSubmit={approve}
              letterActionId={data.id}
              status={status}
              hidden={loading}
            />

            <div className="w-full flex flex-row items-center justify-between px-6 h-20 mb-3 flex-shrink-0">
              <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
                <Mark />
                <h1 className="font-serif font-[600] text-base tracking-[-0.5%]">
                  Lighthouse
                </h1>
              </div>

              <div className="flex flex-row items-center gap-3">
                <button
                  onClick={() => setInformationModalOpen(true)}
                  className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
                >
                  <InfoCircledIcon color="white" width={20} />
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-4 overflow-hidden p-10 pb-0">
              {status === "approved" && (
                <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-4 w-[825px] rounded-lg text-sm text-grey-300">
                  <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
                    <FaRegCircleCheck />
                  </div>

                  <span>
                    You have signed this letter. There is no need for you to
                    take any action.
                  </span>
                </div>
              )}

              <div className="flex flex-row overflow-hidden gap-8">
                <div className="overflow-y-auto no-scrollbar pb-10">
                  <TextEditor key={data.letterId} />
                </div>

                {status !== "approved" &&
                  data.additionalNotes != null &&
                  data.additionalNotes.trim().length > 0 && (
                    <div className="w-[400px] flex flex-col gap-3 bg-grey-800 p-3 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset mb-4 h-fit">
                      <p className="text-sm text-grey-200 font-medium">
                        Additional information:
                      </p>

                      <div
                        className="text-[13px] text-grey-300"
                        dangerouslySetInnerHTML={{
                          __html: data.additionalNotes,
                        }}
                      ></div>
                    </div>
                  )}
              </div>
            </div>
          </EditorContextProvider>
        )}
      </div>
    </div>
  );
};

const LetterSignaturePage = () => {
  const { letterActionId } = Route.useParams();

  const { token, reviewed } = Route.useSearch();

  const [showLanding, setShowLanding] = useState(!reviewed);

  const { data, isPending, error } = useQuery({
    retry: 1,
    queryKey: ["externalLetterAction", letterActionId],
    queryFn: async () => {
      const res = await customerApi.getLetterAction({
        letterActionId: parseInt(letterActionId),
        token: token,
      });

      if (res.success && res.data != null && res.data.type === "sign") {
        return res.data as LetterActionType;
      }

      if (res.message === "403") {
        throw new Error("token expired");
      }

      throw new Error("not found");
    },
  });

  if (error?.message === "token expired")
    return (
      <ExternalTokenExpiredPage
        letterActionId={parseInt(letterActionId)}
        token={token}
      />
    );

  if (error != null) return <LetterActionNotFoundPage external />;

  if (isPending || data == null)
    return (
      <div className="flex flex-col items-center text-center h-screen w-screen justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="h-screen w-screen overflow-y-auto">
      {showLanding && (
        <ExternalLandingPage hide={() => setShowLanding(false)} />
      )}
      <LetterSignatureEditor
        data={data}
        hideInfoModal={showLanding}
        hideInitialInfoModal={reviewed}
      />
    </div>
  );
};

type SignSearch = { token: string; reviewed: boolean };

export const Route = createFileRoute("/external/letter/sign/$letterActionId")({
  component: () => <LetterSignaturePage />,
  validateSearch: (search: Record<string, unknown>): SignSearch => {
    return {
      token: (search.token as string) || "",
      reviewed: search.reviewed === true,
    };
  },
});
