import { cn } from "@/lib/cn";

export const ChatText = (props: {
  as?: "p" | "span";
  bold?: boolean;
  className?: string;
  children: React.ReactNode;
}) => {
  const as = props.as ?? "p";
  return (
    <>
      {as === "span" && (
        <span
          className={cn(
            "leading-[160%] text-sm font-normal text-grey-100",
            props.bold && "font-bold",
            props.className,
          )}
        >
          {props.children}
        </span>
      )}

      {as === "p" && (
        <p
          className={cn(
            "leading-[160%] text-sm font-normal text-grey-100",
            props.bold && "font-bold",
            props.className,
          )}
        >
          {props.children}
        </p>
      )}
    </>
  );
};

export const ButtonText = (props: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div
    className={cn(
      "font-[460] leading-[140%] text-xs flex flex-row gap-1 items-center",
      props.className,
    )}
  >
    {props.children}
  </div>
);

export const InputText = (props: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div className={cn("font-[525] leading-[140%] text-sm", props.className)}>
    {props.children}
  </div>
);

export const LabelText = (props: {
  as?: "label" | "span" | "p";
  bold?: boolean;
  underline?: boolean;
  className?: string;
  children: React.ReactNode;
  htmlFor?: string;
}) => (
  <>
    {(props.as ?? "label") === "label" && (
      <label
        className={cn(
          "leading-[140%] font-normal text-xs",
          props.bold && "font-[525] leading-[140%]",
          props.underline && "underline",
          props.className,
        )}
        htmlFor={props.htmlFor}
      >
        {props.children}
      </label>
    )}

    {(props.as ?? "label") === "span" && (
      <span
        className={cn(
          "leading-[140%] font-normal text-xs",
          props.bold && "font-[525] leading-[140%]",
          props.underline && "underline",
          props.className,
        )}
      >
        {props.children}
      </span>
    )}

    {(props.as ?? "label") === "p" && (
      <p
        className={cn(
          "leading-[140%] font-normal text-xs",
          props.bold && "font-[525] leading-[140%]",
          props.underline && "underline",
          props.className,
        )}
      >
        {props.children}
      </p>
    )}
  </>
);

export const HighlightedText = (props: {
  children: React.ReactNode;
  className?: string;
}) => (
  <span className={cn("text-blue bg-blue-light", props.className)}>
    {props.children}
  </span>
);
