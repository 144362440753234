import { cn } from "@/lib/cn";
import { graphql } from "@/lib/graphql";

type ExternalCaseStatus = ReturnType<typeof graphql.scalar<"ExternalStatus">>;

export const externalCaseStatuses: {
  value: ExternalCaseStatus;
  label: string;
  icon: React.ReactNode;
}[] = [
  {
    value: "pending_documents",
    label: "Collecting documents",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2411_81634)">
          <path
            d="M3.375 11H2.625C2.52554 11 2.43016 10.9605 2.35984 10.8902C2.28951 10.8198 2.25 10.7245 2.25 10.625V9.125"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.625 2H7.125L9.75 4.625V6.875"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25 3.5V2.375C2.25 2.27554 2.28951 2.18016 2.35984 2.10984C2.43016 2.03951 2.52554 2 2.625 2H3.75"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.125 2V4.625H9.75"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.75 8.75V10.625C9.75 10.7245 9.71049 10.8198 9.64017 10.8902C9.56984 10.9605 9.47446 11 9.375 11H9"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25 5.375V7.25"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.25 11H7.125"
            stroke="#1159F5"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2411_81634">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    value: "in_progress",
    label: "Drafting Application",
    icon: (
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2411_74007)">
          <path
            d="M7.49967 1.33301L9.16634 2.99967M0.833008 9.66634L1.36484 7.71628C1.39954 7.58906 1.41689 7.52545 1.44352 7.46613C1.46717 7.41346 1.49622 7.36339 1.53022 7.31672C1.56851 7.26417 1.61514 7.21755 1.70838 7.1243L6.01397 2.81871C6.09648 2.73621 6.13773 2.69495 6.1853 2.6795C6.22714 2.6659 6.27221 2.6659 6.31405 2.6795C6.36162 2.69495 6.40287 2.73621 6.48538 2.81871L7.68064 4.01397C7.76314 4.09648 7.80439 4.13773 7.81985 4.1853C7.83345 4.22714 7.83345 4.27221 7.81985 4.31405C7.80439 4.36162 7.76314 4.40287 7.68064 4.48538L3.37505 8.79096C3.2818 8.88421 3.23518 8.93084 3.18263 8.96913C3.13596 9.00312 3.08589 9.03218 3.03322 9.05583C2.9739 9.08246 2.91029 9.09981 2.78307 9.13451L0.833008 9.66634Z"
            stroke="#666666"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2411_74007">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    value: "in_final_review",
    label: "Application is ready for review",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.37492 10.9999H2.625C2.52554 10.9999 2.43016 10.9604 2.35983 10.8901C2.28951 10.8197 2.25 10.7244 2.25 10.6249V2.375C2.25 2.27554 2.28951 2.18016 2.35983 2.10983C2.43016 2.03951 2.52554 2 2.625 2H7.12494L9.74991 4.62497V10.6249C9.74991 10.7244 9.7104 10.8197 9.64008 10.8901C9.56975 10.9604 9.47437 10.9999 9.37492 10.9999Z"
          stroke="#D24491"
          strokeWidth="0.749991"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 2V4.62497H9.74997"
          stroke="#D24491"
          strokeWidth="0.749991"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.875 7.24999L5.99999 6.125L7.12497 7.24999"
          stroke="#D24491"
          strokeWidth="0.749991"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9.12497V6.125"
          stroke="#D24491"
          strokeWidth="0.749991"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    value: "submitted",
    label: "Submitted & shipped",
    icon: (
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2411_81250)">
          <path
            d="M5.50098 5.54688V9.96801"
            stroke="#666666"
            strokeWidth="0.687579"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.40527 3.30469L5.50067 5.5462L9.59606 3.30469"
            stroke="#666666"
            strokeWidth="0.687579"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.44718 7.85641L5.66549 9.92689C5.6149 9.95457 5.55815 9.96908 5.50048 9.96908C5.4428 9.96908 5.38605 9.95457 5.33546 9.92689L1.55377 7.85641C1.49977 7.82687 1.4547 7.78337 1.42325 7.73045C1.3918 7.67754 1.37514 7.61715 1.375 7.5556V3.44473C1.37514 3.38318 1.3918 3.32279 1.42325 3.26988C1.4547 3.21696 1.49977 3.17346 1.55377 3.14392L5.33546 1.07344C5.38605 1.04576 5.4428 1.03125 5.50048 1.03125C5.55815 1.03125 5.6149 1.04576 5.66549 1.07344L9.44718 3.14392C9.50118 3.17346 9.54626 3.21696 9.5777 3.26988C9.60915 3.32279 9.62581 3.38318 9.62595 3.44473V7.55474C9.62597 7.61644 9.60938 7.677 9.57792 7.73008C9.54647 7.78316 9.50131 7.8268 9.44718 7.85641Z"
            stroke="#666666"
            strokeWidth="0.687579"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.50488 2.0752L7.56332 4.29651V6.53114"
            stroke="#666666"
            strokeWidth="0.687579"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2411_81250">
            <rect
              width="11.0013"
              height="11.0013"
              fill="white"
              transform="translate(0 -0.000976562)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    value: "in_review_uscis",
    label: "Application in USCIS review",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2411_78312)">
          <path
            d="M6 2V3.5"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 6.5H9"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.18188 9.68188L8.12109 8.62109"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 11V9.5"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.81836 9.68188L3.87914 8.62109"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 6.5H3"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.81836 3.31836L3.87914 4.37914"
            stroke="#2C2C2C"
            strokeWidth="0.785714"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2411_78312">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    value: "approved",
    label: "Approved",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
          stroke="#208011"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.125 6.875L5.25 8L7.875 5.375"
          stroke="#208011"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    value: "denied",
    label: "Denied",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
          stroke="#CC2318"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 4.5L4 8.5"
          stroke="#CC2318"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4.5L8 8.5"
          stroke="#CC2318"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    value: "rfe",
    label: "RFE",
    icon: (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2411_64466)">
          <path
            d="M6.67576 2.38531L10.7754 9.50422C11.0628 10.0058 10.6915 10.625 10.1 10.625H1.9006C1.30904 10.625 0.937791 10.0058 1.22513 9.50422L5.32482 2.38531C5.62013 1.87156 6.38045 1.87156 6.67576 2.38531Z"
            stroke="#C18700"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 7.25V5.375"
            stroke="#C18700"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.0625 8.9375C6.0625 8.97202 6.03452 9 6 9C5.96548 9 5.9375 8.97202 5.9375 8.9375C5.9375 8.90298 5.96548 8.875 6 8.875C6.03452 8.875 6.0625 8.90298 6.0625 8.9375Z"
            fill="#C18700"
            stroke="#C18700"
          />
        </g>
        <defs>
          <clipPath id="clip0_2411_64466">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export const externalStatusColorMapping: Record<ExternalCaseStatus, string> = {
  pending_documents: "#1159F5",
  in_progress: "#66666614",
  in_final_review: "#D2449114",
  submitted: "#66666614",
  in_review_uscis: "#2C2C2C14",
  approved: "#20801114",
  denied: "#CC231814",
  rfe: "#F7B21329",
};

export const externalStatusClassMapping: Record<
  ExternalCaseStatus,
  { status: string }
> = Object.keys(externalStatusColorMapping).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      status: `bg-[${externalStatusColorMapping[key as ExternalCaseStatus]}] bg-opacity-[8%] text-[${externalStatusColorMapping[key as ExternalCaseStatus]}] ring-[${externalStatusColorMapping[key as ExternalCaseStatus]}] ring-opacity-[8%]`,
    },
  }),
  {} as Record<ExternalCaseStatus, { status: string }>
);

export const ApplicantStatusBadge = (props: { status: ExternalCaseStatus }) => {
  const { status } = props;

  return (
    <div
      className={cn(
        "group flex w-fit flex-row gap-1 items-center rounded-full bg-gray-50 px-2 py-1 text-xs flex-nowrap whitespace-nowrap ring-1 ring-inset ring-opacity-30",
        externalStatusClassMapping[status].status
      )}
    >
      {externalCaseStatuses.find((x) => x.value === status)?.icon}

      <span style={{ color: externalStatusColorMapping[status] }}>
        {externalCaseStatuses.find((x) => x.value === status)?.label ?? ""}
      </span>
    </div>
  );
};

export const ApplicantStatusGraphBadge = (props: {
  status: ExternalCaseStatus;
  count?: number;
  hideText?: boolean;
}) => {
  const { status, count, hideText } = props;

  return (
    <div
      className={cn(
        "group flex flex-row gap-1 items-center text-xs text-gray-600 flex-nowrap whitespace-nowrap",
        `text-[${externalStatusColorMapping[status]}]`
      )}
    >
      <div
        className={cn(
          "rounded-full p-1 ring-1 ring-inset ring-opacity-30 rounded-full bg-gray-50",
          externalStatusClassMapping[status].status
        )}
      >
        {externalCaseStatuses.find((x) => x.value === status)?.icon}
      </div>
      {!hideText && (
        <>
          {externalCaseStatuses.find((x) => x.value === status)?.label ?? ""}
          {count != null && <span className="opacity-50">{count}</span>}
        </>
      )}
    </div>
  );
};
