import { ApplicantStatusBadge } from "@/components/applicantStatusBadge";
import { Avatar } from "@/components/avatar";
import { Button } from "@/components/button";
import { IncomeInput, USStateSelect } from "@/components/inputs";
import { CompanyApplicantDashboardContent } from "@/components/dashboard";
import { Field, SubkeyUploadArea, SubmitModal } from "@/components/form";
import { DraftIcon } from "@/components/icons/draft";
import { InReviewIcon } from "@/components/icons/inReview";
import { CountryAddressInput, DateInput, Input } from "@/components/inputs";
import { PetitionerRepSelector } from "@/components/petitionerRepresentativeSelector";
import { CompanySidebarFrame } from "@/components/sidebarFrame";
import { InputText } from "@/components/typography";
import { VisaClassBadge } from "@/components/visaClassBadge";
import { ResultOf } from "@/lib/graphql";
import { useOnboardingNodeData, useWorkspaces } from "@/lib/hooks";
import { getCase, getCompanyWorkspace } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";
import {
  OnboardingRouteProvider,
  useOnboarding,
} from "@/providers/onboardingProvider";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Spinner } from "@radix-ui/themes";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useQuery } from "urql";

const CompanyOnboardingInner = (props: {
  currentOnboardingNodeId: number;
  onboardingId: number;
}) => {
  const { nodeStatus } = useOnboarding();
  const { data, doSetData, fetching } = useOnboardingNodeData(
    props.currentOnboardingNodeId
  );
  const worksiteAddress = data?.worksiteAddress?.value ?? {};

  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  return (
    <div>
      <SubmitModal
        open={submitModalOpen}
        setOpen={setSubmitModalOpen}
        onboardingId={props.onboardingId}
        currentNodeId={props.currentOnboardingNodeId}
      />

      {fetching && <Spinner />}
      {!fetching && data != null && (
        <div className="flex flex-col gap-4 pb-10 px-1">
          <Field
            id="signedEmploymentOfferLetter"
            label="Signed Employment Offer Letter"
            description="This document will help us prepare the Employment Verification letter. You can review, comment, and collaborate to ensure alignment on the role title, salary, and other details."
          >
            <SubkeyUploadArea
              onboardingNodeId={props.currentOnboardingNodeId}
              subKey="signedEmploymentOfferLetter"
            />
          </Field>

          <Field
            id="intendedRoleTitle"
            label="Intended Role Title"
            description="This is the title the beneficiary will be assigned upon approval."
          >
            <Input
              type="text"
              placeholder="e.g. Software Engineer"
              value={data.intendedRoleTitle?.value as string}
              onValueChange={(val) => doSetData("intendedRoleTitle", val)}
            />
          </Field>

          <Field
            id="intendedStartDate"
            label="Intended Start Date"
            description="This is the start date the beneficiary will be assigned upon approval."
          >
            <DateInput
              value={
                data.intendedStartDate?.value != null
                  ? new Date(data.intendedStartDate.value)
                  : undefined
              }
              onValueChange={(val) =>
                doSetData("intendedStartDate", val?.toISOString())
              }
            />
          </Field>

          <Field
            id="intendedSalary"
            label="Intended Salary"
            description="This is the salary the beneficiary will be assigned upon approval."
          >
            <IncomeInput
              value={data.intendedSalary?.value}
              onValueChange={(val) => doSetData("intendedSalary", val)}
            />
          </Field>

          <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
            <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
              <InputText className="text-grey-300 text-[12px]">
                What is the worksite address?
              </InputText>
              <div id="field-worksiteAddress" className="flex flex-col gap-2">
                <Field label="Address" className="pr-4">
                  <Input
                    placeholder="123 Main St"
                    type="text"
                    value={worksiteAddress.street ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        street: val,
                      })
                    }
                    autoComplete={"street-address"}
                  />
                </Field>
                <Field label="City" className="pr-4">
                  <Input
                    placeholder="San Francisco"
                    type="text"
                    value={worksiteAddress.city ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        city: val,
                      })
                    }
                    autoComplete={"address-level2"}
                  />
                </Field>
                <div className="flex flex-row gap-2 w-full">
                  <Field label="State" className="pr-4">
                    <USStateSelect
                      value={worksiteAddress.state ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          state: val,
                        })
                      }
                    />
                  </Field>
                  <Field label="ZIP / Postal Code" className="pr-4">
                    <Input
                      placeholder="94102"
                      type="text"
                      value={worksiteAddress.zip ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          zip: val,
                        })
                      }
                      autoComplete={"postal-code"}
                    />
                  </Field>
                </div>
                <Field label="Country" className="pr-4">
                  <CountryAddressInput
                    value={worksiteAddress.country}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        country: val as string,
                      })
                    }
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end pr-12">
            {nodeStatus !== "submitted_for_review" && (
              <Button
                variant="primary"
                onClick={() => setSubmitModalOpen(true)}
              >
                <DraftIcon />
                Submit for Review
              </Button>
            )}

            {nodeStatus === "submitted_for_review" && (
              <Button
                variant="primary"
                disabled
                className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
              >
                <InReviewIcon />
                Submitted
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const CompanyApplicantDashboardInner = () => {
  return (
    <div className="w-full flex flex-col pr-12 gap-2">
      <CompanyApplicantDashboardContent />
    </div>
  );
};

const CompanyApplicantDashboard = (props: { onboardingId: number }) => {
  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <CompanyApplicantDashboardInner />
    </OnboardingDataProvider>
  );
};

const KeyValue = (props: { label: string; value: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center gap-8 text-xs h-[25px]">
      <span className="text-grey-300 font-[460] w-[150px] text-nowrap">
        {props.label}
      </span>
      {props.value}
    </div>
  );
};

const ApplicantInfo = (props: { data: ResultOf<typeof getCase> }) => {
  const { data } = props;

  const kvPairs = [
    {
      label: "Application Status",
      value: <ApplicantStatusBadge status={data.getEmployeeCase.status} />,
    },
    {
      label: "Application in progress",
      value: <VisaClassBadge visaClass={data.getEmployeeCase.visaClass} />,
    },
    {
      label: "Employer Representative",
      value: (
        <PetitionerRepSelector
          caseId={data.getEmployeeCase.id}
          companyId={data.getEmployeeCase.company.id}
        />
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col gap-2 pr-12">
      <div className="flex flex-row items-center gap-2 text-sm text-grey-100 font-[525]">
        <Avatar
          username={data.getEmployeeCase.beneficiaryName}
          className="rounded-full w-[20px] h-[20px]"
        />
        {data.getEmployeeCase.beneficiaryName}
      </div>
      <div className="flex flex-col gap-2 mt-2 px-0.5">
        {kvPairs.map((kv) => (
          <KeyValue key={kv.label} label={kv.label} value={kv.value} />
        ))}
      </div>

      <div className="border-b-[1px] border-grey-600 w-full my-2" />
    </div>
  );
};

const CompanyOnboardingNode = (props: {
  onboardingNodeId: number;
  onboardingId: number;
}) => {
  const { onboardingNodeId, onboardingId } = props;

  return (
    <div className="w-full flex flex-col gap-4">
      <OnboardingRouteProvider
        onboardingId={onboardingId}
        currentGroup="employer_documents"
        currentNodeId={onboardingNodeId}
      >
        <CompanyOnboardingInner
          currentOnboardingNodeId={onboardingNodeId}
          onboardingId={onboardingId}
        />
      </OnboardingRouteProvider>
    </div>
  );
};

const ApplicationPage = () => {
  const { caseId } = Route.useParams();

  const { selectedWorkspace } = useWorkspaces();
  const [{ data: companyWorkspaceData }] = useQuery({
    query: getCompanyWorkspace,
    variables: { id: parseInt(selectedWorkspace?.id.split("-")[1] ?? "-1") },
    pause: selectedWorkspace?.id == null,
    requestPolicy: "cache-and-network",
  });

  const [{ data }] = useQuery({
    query: getCase,
    variables: {
      caseId: Number(caseId),
    },
    requestPolicy: "cache-and-network",
  });

  const nav = useNavigate();

  const currIndex = companyWorkspaceData?.getCompanyWorkspace.cases.findIndex(
    (x) => x.id === Number(caseId)
  );

  const nextAllowed =
    currIndex == null
      ? false
      : currIndex <
        (companyWorkspaceData?.getCompanyWorkspace.cases.length ?? 0) - 1;

  const previousAllowed = currIndex == null ? false : currIndex > 0;

  const onNext = () => {
    if (nextAllowed) {
      const nextCaseId =
        companyWorkspaceData?.getCompanyWorkspace.cases[Number(currIndex) + 1]
          ?.id;

      if (nextCaseId == null) return;

      nav({
        to: `/applicants/$caseId`,
        params: { caseId: nextCaseId.toString() },
      });
    }
  };

  const onPrevious = () => {
    if (previousAllowed) {
      const previousCaseId =
        companyWorkspaceData?.getCompanyWorkspace.cases[Number(currIndex) - 1]
          ?.id;

      if (previousCaseId == null) return;
      nav({
        to: `/applicants/$caseId`,
        params: { caseId: previousCaseId.toString() },
      });
    }
  };
  return (
    <CompanyProvider>
      <CompanySidebarFrame>
        <div className="w-full h-full flex bg-grey-800 pl-6">
          <div className="w-full h-full relative flex">
            {previousAllowed && (
              <Button
                variant="secondary"
                className="absolute bottom-2 left-5 shadow-border bg-grey-700 z-10"
                onClick={onPrevious}
              >
                Previous Applicant
              </Button>
            )}
            {nextAllowed && (
              <Button
                variant="secondary"
                className="absolute bottom-2 right-5 shadow-border bg-grey-700 z-10"
                onClick={onNext}
              >
                Next Applicant
              </Button>
            )}
            <div className="pt-16 w-[33%]">
              <div
                className="flex flex-row gap-2 items-center text-grey-200 text-xs hover:underline cursor-pointer"
                onClick={() => nav({ to: "/home" })}
              >
                <ArrowLeftIcon />
                Back to all applicants
              </div>
            </div>

            <div className="flex flex-col gap-4 w-[45%] py-6 overflow-scroll">
              {data != null && data.getEmployeeCase != null ? (
                <ApplicantInfo data={data} />
              ) : (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <Spinner />
                </div>
              )}

              <div>
                {data?.getEmployeeCase.companyOnboarding
                  ?.employerDetailsNodeStatus === "approved" && (
                  <CompanyApplicantDashboard
                    onboardingId={data.getEmployeeCase.companyOnboarding.id}
                  />
                )}
                {data?.getEmployeeCase.companyOnboarding?.id != null &&
                  data.getEmployeeCase.companyOnboarding
                    .employerDetailsNodeId != null && (
                    <CompanyOnboardingNode
                      onboardingNodeId={
                        data.getEmployeeCase.companyOnboarding
                          .employerDetailsNodeId
                      }
                      onboardingId={data.getEmployeeCase.companyOnboarding.id}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

export const Route = createFileRoute("/_portal/applicants/$caseId")({
  beforeLoad: async ({ context, params }) => {
    if (params.caseId != null) {
      context.client.query(getCase, {
        caseId: Number(params.caseId),
      });
    }
  },
  component: () => <ApplicationPage />,
});
