import { useEditorContext } from "@/providers/editorProvider";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { FocusEvent, useCallback, useEffect, useRef, useState } from "react";
import * as Y from "yjs";
import uniqolor from "uniqolor";
import moment from "moment";
import {
  FiCheck,
  FiChevronDown,
  FiChevronUp,
  FiMoreHorizontal,
} from "react-icons/fi";
import { cn } from "@/lib/cn";
import { useRerenderOnYObjectChange } from "@/lib/hooks";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ArrowUpIcon, TrashIcon } from "@radix-ui/react-icons";
import { Button } from "./button";
import { Mark } from "./mark";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

/*
The CommentYMap type is a Y.Map that stores the following keys:
{
  id: string // same as mark id
  userId: string
  timestamp: number
  internal: boolean
  externalUserName: string
  resolved: boolean
  content: string
  completed: boolean
  replies: Y.Array<Omit<CommentYMap, 'replies'>>
}
*/

type ReplyYMap = Y.Map<string | number | boolean>;

export type CommentYMap = Y.Map<string | number | boolean | Y.Array<ReplyYMap>>;

const timeSince = (timestamp: number) => {
  const duration = moment.duration(moment().diff(moment(timestamp)));

  if (duration.asSeconds() < 60) return Math.floor(duration.asSeconds()) + "s";

  if (duration.asMinutes() < 60) return Math.floor(duration.asMinutes()) + "m";

  if (duration.asHours() < 24) return Math.floor(duration.asHours()) + "h";

  return Math.floor(duration.asDays()) + "d";
};

const NewComment = (props: { comment: CommentYMap; onComment: () => void }) => {
  const { comment, onComment } = props;

  const { editor, provider, userName } = useEditorContext();

  const commentRef = useRef<HTMLDivElement>(null);

  const deleteComment = useCallback(() => {
    if (editor == null || provider == null) return;

    editor.commands.deleteCommentHighlightById(comment.get("id") as string);

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === comment.get("id")) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    comments.delete(index);

    setContent("");

    if (commentRef.current == null) return;

    commentRef.current.textContent = "";
    commentRef.current.innerHTML = "";
  }, [comment, editor, provider]);

  useEffect(() => {
    commentRef.current?.focus();
    window.addEventListener("beforeunload", deleteComment);

    return () => {
      window.removeEventListener("beforeunload", deleteComment);
    };
  }, [deleteComment]);

  const createComment = () => {
    if (content === "") return;

    comment.set("content", content);
    comment.set("completed", true);

    onComment();

    setContent("");

    if (commentRef.current == null) return;

    commentRef.current.textContent = "";
    commentRef.current.innerHTML = "";
  };

  const [content, setContent] = useState("");

  return (
    <div className="w-[400px] flex flex-col gap-1 bg-grey-800 p-3 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset">
      <div className="flex flex-row gap-2">
        <div
          className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs flex-shrink-0"
          style={{
            backgroundColor: uniqolor(userName).color,
          }}
        >
          <span>{userName[0]}</span>
        </div>

        <div
          className={cn(
            "flex flex-1 min-h-[22px] pb-1.5 border-[1px] text-[13px] mt-0.5 outline-none border-none group/comment break-all"
          )}
          data-placeholder="Write a comment..."
          contentEditable="plaintext-only"
          suppressContentEditableWarning
          ref={commentRef}
          onInput={(e) => setContent(e.currentTarget.textContent ?? "")}
        >
          {content === "" && (
            <span className="opacity-50 pointer-events-none group-focus/comment:hidden">
              Write a comment...
            </span>
          )}
        </div>

        {content === "" && (
          <Button
            variant="secondary"
            className="h-7 w-7 flex items-center justify-center p-0 bg-grey-600"
            onClick={deleteComment}
          >
            <TrashIcon width={30} />
          </Button>
        )}
      </div>

      {content !== "" && (
        <div className="flex flex-row items-center justify-end gap-2">
          <Button
            variant="secondary"
            className="h-7 w-7 flex items-center justify-center p-0 bg-grey-600"
            onClick={deleteComment}
          >
            <TrashIcon width={30} />
          </Button>

          <Button
            variant="primary"
            className="h-7 w-7 flex items-center justify-center p-0 disabled:bg-grey-400"
            onClick={createComment}
          >
            <ArrowUpIcon width={30} />
          </Button>
        </div>
      )}
    </div>
  );
};

const NewReply = (props: {
  parentCommentId: string;
  hide?: boolean;
  onReply: () => void;
}) => {
  const { parentCommentId, hide, onReply } = props;

  const { provider, userId, userName } = useEditorContext();

  const commentRef = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState("");

  const createComment = () => {
    if (provider == null || content === "") return;

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === parentCommentId) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    const commentToUpdate = comments.get(index);

    const newComment: CommentYMap = new Y.Map([
      ["id", `${Date.now()}-${String(Math.random()).slice(2)}`],
      ["userId", userId],
      ["timestamp", Date.now()],
      ["content", content],
      ["externalUserName", userName],
      ["internal", false],
      ["completed", true],
      ["resolved", false],
    ]);

    const replies = commentToUpdate.get("replies") as Y.Array<CommentYMap>;

    onReply();

    replies.push([newComment]);
    clearContent();
  };

  const clearContent = () => {
    if (content === "") return;
    setContent("");

    if (commentRef.current == null) return;

    commentRef.current.textContent = "";
    commentRef.current.innerHTML = "";
  };

  return (
    <div
      className={cn(
        "flex flex-row border-t border-grey-600 pt-3 mt-2 gap-2",
        hide && content === "" && "hidden"
      )}
    >
      <div
        className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs flex-shrink-0"
        style={{
          backgroundColor: uniqolor(userName).color,
        }}
      >
        <span>{userName[0].toUpperCase()}</span>
      </div>

      <div
        className={cn(
          "flex flex-1 w-full min-h-[22px] pb-1.5 border-[1px] text-[13px] mt-0.5 outline-none border-none group/comment break-all"
        )}
        data-placeholder="Reply..."
        contentEditable="plaintext-only"
        suppressContentEditableWarning
        ref={commentRef}
        onInput={(e) => setContent(e.currentTarget.textContent ?? "")}
      >
        {content === "" && (
          <span className="opacity-50 pointer-events-none group-focus/comment:hidden">
            Reply...
          </span>
        )}
      </div>

      <Button
        variant="primary"
        disabled={content === ""}
        className="h-7 w-7 flex items-center justify-center p-0 disabled:bg-grey-400 self-end"
        onClick={createComment}
      >
        <ArrowUpIcon width={30} />
      </Button>
    </div>
  );
};

const CommentMenu = (props: {
  handleDelete: () => void;
  handleEdit: () => void;
}) => {
  const { handleDelete, handleEdit } = props;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="text-xl text-grey-400 hover:text-grey-200">
        <FiMoreHorizontal />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        side="left"
        sideOffset={10}
        className="rounded-md ring-1 ring-grey-200 ring-opacity-5 focus:outline-none bg-white"
      >
        <div className="py-0.5">
          <DropdownMenu.Item>
            <button
              onClick={handleEdit}
              className="flex flex-row items-center gap-2 text-xs text-grey-300 px-3 py-1 w-full"
            >
              Edit
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <button
              onClick={handleDelete}
              className="flex flex-row items-center gap-2 text-xs text-[#d36161] hover:bg-[#fee2e2] px-3 py-1 w-full"
            >
              Delete
            </button>
          </DropdownMenu.Item>
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const CommentViewer = (props: { comment: CommentYMap | ReplyYMap }) => {
  const { comment } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  const isExpandable =
    contentRef.current && contentRef.current.clientHeight >= 150;

  return (
    <div className="flex flex-col">
      <div
        className={cn(
          "overflow-hidden text-[13px] text-grey-300 whitespace-pre-wrap break-all",
          !expanded && "max-h-[150px]"
        )}
        ref={contentRef}
      >
        {comment.get("content") as string}
      </div>

      {isExpandable && !expanded && (
        <button
          onClick={() => setExpanded((prev) => !prev)}
          className="flex text-sm items-center gap-1 text-blue-500"
        >
          <span>Show more</span>
          <FiChevronDown />
        </button>
      )}

      {isExpandable && expanded && (
        <button
          onClick={() => setExpanded((prev) => !prev)}
          className="flex text-sm items-center gap-1 text-blue-500 mt-2"
        >
          <span>Show less</span>
          <FiChevronUp />
        </button>
      )}
    </div>
  );
};

const CommentEditor = (props: {
  comment: CommentYMap | ReplyYMap;
  parentCommentId?: string;
  setIsEditing: (value: boolean) => void;
}) => {
  const { comment, parentCommentId, setIsEditing } = props;

  const { provider, handleFocusComment } = useEditorContext();

  const commentRef = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState(comment.get("content") as string);

  useEffect(() => {
    if (commentRef.current == null) return;

    commentRef.current.textContent = content;
  }, [commentRef, content]);

  const saveComment = () => {
    if (provider == null) return;

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    if (parentCommentId != null) {
      let parentIndex = -1;

      for (let i = 0; i < comments.length; i++) {
        if (comments.get(i).get("id") === parentCommentId) {
          parentIndex = i;
          break;
        }
      }

      if (parentIndex === -1) return;

      const replies = comments
        .get(parentIndex)
        .get("replies") as Y.Array<ReplyYMap>;

      let index = -1;

      for (let i = 0; i < replies.length; i++) {
        if (replies.get(i).get("id") === comment.get("id")) {
          index = i;
          break;
        }
      }

      if (index === -1) return;

      const commentToUpdate = replies.get(index);

      commentToUpdate.set("content", content);
      setIsEditing(false);
      return;
    }

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === comment.get("id")) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    const commentToUpdate = comments.get(index);

    commentToUpdate.set("content", content);
    setIsEditing(false);
  };

  const discard = () => {
    setContent("");
    setIsEditing(false);
    handleFocusComment(null);
  };

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div
        className="flex flex-col w-full text-[13px] outline-none group/comment ring-1 ring-grey-600 ring-inset p-3 rounded-md break-all"
        data-placeholder="Write a comment..."
        contentEditable="plaintext-only"
        suppressContentEditableWarning
        ref={commentRef}
        onInput={(e) => setContent(e.currentTarget.textContent ?? "")}
      >
        {content === "" && (
          <span className="opacity-50 pointer-events-none group-focus/comment:hidden">
            Write a comment...
          </span>
        )}
      </div>

      <div className="flex flex-row items-center justify-end gap-2">
        <Button variant="secondary" onClick={discard}>
          <span className="text-xs">Discard</span>
        </Button>

        <Button
          variant="primary"
          disabled={content === ""}
          onClick={saveComment}
        >
          <span className="text-xs">Save</span>
        </Button>
      </div>
    </div>
  );
};

const Reply = (props: { parentCommentId: string; comment: ReplyYMap }) => {
  const { comment, parentCommentId } = props;

  const { focusedCommentId, userId, provider } = useEditorContext();

  const [isEditing, setIsEditing] = useState(false);

  const deleteComment = () => {
    if (provider == null) return;

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    let parentIndex = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === parentCommentId) {
        parentIndex = i;
        break;
      }
    }

    if (parentIndex === -1) return;

    const replies = comments
      .get(parentIndex)
      .get("replies") as Y.Array<ReplyYMap>;

    let index = -1;

    for (let i = 0; i < replies.length; i++) {
      if (replies.get(i).get("id") === comment.get("id")) {
        index = i;
        break;
      }
    }

    replies.delete(index);
  };

  const isLighthouseAuthor = comment.get("lighthouseAuthor") as boolean;
  const authorName = isLighthouseAuthor
    ? "Lighthouse"
    : ((comment.get("externalUserName") as string) ?? "Anonymous");

  return (
    <div className="flex flex-row gap-2">
      {isLighthouseAuthor && <Mark />}

      {!isLighthouseAuthor && (
        <div
          className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs"
          style={{
            backgroundColor: uniqolor(authorName).color,
          }}
        >
          <span>{authorName[0].toUpperCase()}</span>
        </div>
      )}

      <div className="flex flex-col gap-1 flex-1">
        <div className="flex flex-row items-center justify-between w-full">
          <span className="font-medium text-[13px] text-grey-200">
            {authorName}
            <span className="font-medium text-xs text-grey-400 ml-1.5">
              {timeSince(comment.get("timestamp") as number)}
            </span>
          </span>

          <div className="flex flex-row items-center">
            <div
              className={cn(
                "flex flex-row items-center gap-3 opacity-0 transition-opacity group-hover/container:opacity-100",
                focusedCommentId === parentCommentId && "opacity-100"
              )}
            >
              {userId === comment.get("userId") && !isEditing && (
                <CommentMenu
                  handleDelete={deleteComment}
                  handleEdit={() => setIsEditing(true)}
                />
              )}
            </div>
          </div>
        </div>

        {!isEditing && <CommentViewer comment={comment} />}

        {isEditing && (
          <CommentEditor
            parentCommentId={parentCommentId}
            comment={comment}
            setIsEditing={setIsEditing}
          />
        )}
      </div>
    </div>
  );
};

const Comment = (props: { comment: CommentYMap; onReply: () => void }) => {
  const { comment, onReply } = props;

  const { provider, userId, focusedCommentId, editor, handleFocusComment } =
    useEditorContext();

  const [isEditing, setIsEditing] = useState(false);

  const deleteComment = () => {
    if (editor == null || provider == null) return;

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    editor.commands.deleteCommentHighlightById(comment.get("id") as string);

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === comment.get("id")) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    comments.delete(index);
    handleFocusComment(null);
  };

  const handleBlur = (e: FocusEvent) => {
    const currentTarget = e.currentTarget;

    requestAnimationFrame(() => {
      if (
        !currentTarget.contains(document.activeElement) &&
        document.activeElement?.getAttribute("data-type") !== "comment"
      ) {
        handleFocusComment(null);
      }
    });
  };

  const resolveComment = () => {
    if (editor == null || provider == null) return;

    const comments: Y.Array<CommentYMap> =
      provider.document.getArray("comments");

    let index = -1;

    for (let i = 0; i < comments.length; i++) {
      if (comments.get(i).get("id") === comment.get("id")) {
        index = i;
        break;
      }
    }

    if (index === -1) return;

    const commentToUpdate = comments.get(index);

    editor.commands.setCommentHighlightResolvedById(
      commentToUpdate.get("id") as string,
      true
    );

    commentToUpdate.set("resolved", true);
  };

  const isLighthouseAuthor = comment.get("lighthouseAuthor") as boolean;
  const commentAuthorName = isLighthouseAuthor
    ? "Lighthouse"
    : ((comment.get("externalUserName") as string) ?? "Anonymous");

  const replies = comment.get("replies") as Y.Array<ReplyYMap>;

  return (
    <div
      tabIndex={0}
      data-type="comment"
      onFocus={() => handleFocusComment(comment.get("id") as string)}
      onBlur={handleBlur}
      className={cn(
        "w-[400px] flex flex-col gap-1 bg-grey-800 p-3 rounded-lg transition-all group/container ring-2 ring-grey-600 ring-inset",
        focusedCommentId === comment.get("id") && "bg-white"
      )}
    >
      <div className="flex flex-row gap-2">
        {isLighthouseAuthor && <Mark />}

        {!isLighthouseAuthor && (
          <div
            className="flex items-center justify-center h-[22px] w-[22px] rounded-full font-bold text-white text-xs"
            style={{
              backgroundColor: uniqolor(commentAuthorName).color,
            }}
          >
            <span>{commentAuthorName[0].toUpperCase()}</span>
          </div>
        )}

        <div className="flex flex-col gap-1 flex-1">
          <div className="flex flex-row items-center justify-between w-full">
            <span className="font-medium text-[13px] text-grey-200">
              {commentAuthorName}
              <span className="font-medium text-xs text-grey-400 ml-1.5">
                {timeSince(comment.get("timestamp") as number)}
              </span>
            </span>

            <div className="flex flex-row items-center">
              <div
                className={cn(
                  "flex flex-row items-center gap-3 opacity-0 transition-opacity group-hover/container:opacity-100",
                  focusedCommentId === comment.get("id") && "opacity-100"
                )}
              >
                {!isEditing && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        onClick={resolveComment}
                        className="text-blue text-lg"
                        variant="tooltip"
                      >
                        <FiCheck />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="p-2">
                      Resolve comment
                    </TooltipContent>
                  </Tooltip>
                )}

                {userId === comment.get("userId") && !isEditing && (
                  <CommentMenu
                    handleDelete={deleteComment}
                    handleEdit={() => setIsEditing(true)}
                  />
                )}
              </div>
            </div>
          </div>

          {!isEditing && <CommentViewer comment={comment} />}

          {isEditing && (
            <CommentEditor comment={comment} setIsEditing={setIsEditing} />
          )}
        </div>
      </div>

      {replies.length > 0 && (
        <div className="flex flex-col gap-3 mt-2">
          {replies.map((reply) => {
            return (
              <Reply
                key={reply.get("id") as string}
                parentCommentId={comment.get("id") as string}
                comment={reply}
              />
            );
          })}
        </div>
      )}

      <NewReply
        onReply={onReply}
        hide={focusedCommentId !== comment.get("id")}
        parentCommentId={comment.get("id") as string}
      />
    </div>
  );
};

const getFilteredComments = (
  provider: HocuspocusProvider | null,
  authorWhitelist: string[]
) => {
  if (provider == null) return [];

  const comments = provider.document.getArray(
    "comments"
  ) as Y.Array<CommentYMap>;

  const filteredComments = [];

  for (let i = 0; i < provider.document.getArray("comments").length; i++) {
    const comment = comments.get(i);

    if (comment.get("internal") !== false) continue;
    if (comment.get("resolved") === true) continue;
    if (comment.get("completed") === false) continue;
    if (comment.get("lighthouseAuthor") === true) {
      filteredComments.push(comment);
      continue;
    }
    if (
      comment.get("userId") == null ||
      !authorWhitelist.includes(comment.get("userId") as string)
    )
      continue;

    filteredComments.push(comments.get(i));
  }

  return filteredComments;
};

const getNewComment = (provider: HocuspocusProvider | null, userId: string) => {
  if (userId == null || provider == null) return;

  const comments = provider.document.getArray(
    "comments"
  ) as Y.Array<CommentYMap>;

  for (let i = 0; i < provider.document.getArray("comments").length; i++) {
    const comment = comments.get(i);

    if (
      comment.get("userId") === userId &&
      comment.get("completed") === false
    ) {
      return comment;
    }
  }
};

export const TextEditorComments = (props: { onCommentAdded: () => void }) => {
  const { onCommentAdded } = props;

  const { provider, ready, userId, commentUserIdWhitelist } =
    useEditorContext();

  useRerenderOnYObjectChange(provider?.document?.getArray("comments"));

  const newComment = getNewComment(provider, userId);

  const comments = getFilteredComments(provider, commentUserIdWhitelist);

  return (
    <div className="flex h-full flex-col gap-3 flex-shrink-0">
      {provider != null && ready && (
        <div className="flex flex-col gap-4">
          {newComment != null && (
            <NewComment
              onComment={onCommentAdded}
              comment={newComment}
              key={(newComment.get("id") ?? "") as string}
            />
          )}
          <div className="flex flex-col gap-4">
            {comments.map((comment) => (
              <Comment
                key={comment.get("id") as number}
                comment={comment}
                onReply={onCommentAdded}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
