import { CompanyDashboard, Dashboard } from "@/components/dashboard";
import { EmployerTutorialModal } from "@/components/employerTutorial";
import { NoOnboardings } from "@/components/noOnboardings";
import { CompanySidebarFrame, SidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces, useWorkspaceStore } from "@/lib/hooks";
import { getOnboarding, getOnboardingContext } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";
import { Spinner } from "@radix-ui/themes";
import {
  createFileRoute,
  redirect,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { useQuery } from "urql";

const CompanyHome = () => {
  return (
    <CompanyProvider>
      <CompanySidebarFrame>
        <EmployerTutorialModal />
        <CompanyDashboard />
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

const UserHome = (props: { onboardingId: number }) => {
  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: { id: props.onboardingId },
    requestPolicy: "cache-and-network",
  });
  const nav = useNavigate();
  const router = useRouter();

  useEffect(() => {
    if (data == null) return;

    for (const group of data.onboarding.groups) {
      if (group.nodes.length === 0) continue;

      const firstNode = group.nodes[0];
      try {
        router.preloadRoute({
          to: "/group/$groupId",
          params: { groupId: group.id },
          search: { nodeId: firstNode.id },
        });
      } catch (e) {
        // console.error(e);
      }
    }
  }, [data, router]);

  if (data == null) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const gatedOnboarding = data?.onboarding.groups.find((x) => x.id === "gate");

  if ((gatedOnboarding?.nodes.filter((x) => !x.completed) ?? []).length > 0) {
    nav({ to: "/group/$groupId", params: { groupId: "gate" } });
    return;
  }

  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <SidebarFrame>
        <Dashboard onboardingId={props.onboardingId} />
      </SidebarFrame>
    </OnboardingDataProvider>
  );
};

const WorkspaceHome = () => {
  const { selectedWorkspace, fetching } = useWorkspaces();
  const onboardingList = selectedWorkspace?.onboardings ?? [];

  return (
    <div className="w-screen h-screen max-w-screen">
      {selectedWorkspace == null && fetching && (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {selectedWorkspace != null && (
        <>
          {selectedWorkspace.id.startsWith("company") && <CompanyHome />}

          {selectedWorkspace.id.startsWith("user") && (
            <>
              {onboardingList.length > 0 &&
              selectedWorkspace.selectedOnboardingId != null ? (
                <UserHome
                  onboardingId={selectedWorkspace.selectedOnboardingId}
                />
              ) : (
                <NoOnboardings />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const Route = createFileRoute("/_portal/home")({
  preload: true,
  beforeLoad: async ({ context }) => {
    const res = await context.client.query(getOnboardingContext, {
      contextInput: {},
    });

    const localSelectedWorkspace =
      useWorkspaceStore.getState().selectedWorkspace;

    if (res.data == null || res.data.context.workspaces.length === 0) {
      return;
    }
    // validate if cached workspace is on server

    if (
      localSelectedWorkspace != null &&
      res.data.context.workspaces.find(
        (x) => x.id === localSelectedWorkspace.id
      ) == null
    ) {
      const firstWorkspace = res.data.context.workspaces[0];

      useWorkspaceStore.setState({
        selectedWorkspace: {
          ...firstWorkspace,
          selectedOnboardingId: firstWorkspace.onboardings[0].id,
        },
      });
    }

    const finalSelectedWorkspace =
      useWorkspaceStore.getState().selectedWorkspace;

    if (finalSelectedWorkspace == null) return;
    if (finalSelectedWorkspace?.onboardings.length === 0) return;

    const firstOnboarding = finalSelectedWorkspace.onboardings[0];

    const onboardingData = await context.client.query(getOnboarding, {
      id: firstOnboarding.id,
    });

    const gateNodes = onboardingData.data?.onboarding.groups.find(
      (x) => x.id === "gate"
    )?.nodes;

    if (gateNodes == null || gateNodes.length === 0) return;

    const incompleteNodes = gateNodes.filter((x) => !x.completed);

    if (incompleteNodes.length === 0) return;

    throw redirect({
      to: "/group/$groupId",
      params: { groupId: "gate" },
      search: { nodeId: incompleteNodes[0].id },
    });
  },
  component: () => <WorkspaceHome />,
});
