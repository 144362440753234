import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useUserContext, useWorkspaces } from "@/lib/hooks";
import { cn } from "@/lib/cn";

import { MarkRoundedSquareIcon } from "@/components/icons/markRoundedSquare";
import { Heading, Spinner } from "@radix-ui/themes";
import { LabelText } from "@/components/typography";
import { useEffect, useState } from "react";
import { FormCard } from "@/components/form";
import { Input, PhoneNumberInput } from "@/components/inputs";
import { Button } from "@/components/button";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { isMissingOrEmpty } from "@/lib/utils";
import { RadioSelect } from "@/components/radio";
import { PhoneNumber } from "react-phone-number-input";
import {
  getOnboardingContext,
  getWorkspaceInvite,
  markInviteAsAccepted,
  patchUserEntityMutation,
} from "@/lib/queries";
import { useClient, useMutation, useQuery } from "urql";

const EnterToContinue = (props: { hintVisible?: boolean }) => (
  <LabelText
    as="span"
    className={cn(
      "text-grey-400 transition-all duration-500 opacity-0",
      props.hintVisible && "opacity-100"
    )}
  >
    Press{" "}
    <kbd className="bg-grey-600 border border-grey-500 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
      Enter
    </kbd>{" "}
    to continue
  </LabelText>
);

const Splash = (props: { onNext?: () => void; workspaceName?: string }) => {
  const [h1Visible, setH1Visible] = useState(false);
  const [h2Visible, setH2Visible] = useState(false);
  const [hintVisible, setHintVisible] = useState(false);
  const [transitionStart, setTransitionStart] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setH1Visible(true);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setH2Visible(true);
    }, 750);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHintVisible(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        setTransitionStart(true);

        setTimeout(() => {
          props.onNext?.();
        }, 250);
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [props]);

  return (
    <div
      className={cn(
        "gap-2 flex flex-col items-center justify-center w-full",
        transitionStart && "opacity-0",
        !transitionStart && "opacity-100",
        "transition-all duration-500"
      )}
    >
      <Heading
        align="center"
        weight="regular"
        className={cn(
          "transition-all duration-500 opacity-0",
          h1Visible && "opacity-100"
        )}
      >
        You've been invited to join the{" "}
        <strong>{props.workspaceName ?? "Test"}</strong> workspace
      </Heading>
      <h2
        className={cn(
          "text-center text-sm text-grey-300",
          "transition-all duration-500 opacity-0",
          h2Visible && "opacity-100"
        )}
      >
        We need a little more information from you before we can get started.
      </h2>

      <div className="mt-2">
        <EnterToContinue hintVisible={hintVisible} />
      </div>
    </div>
  );
};

const Field = (props: {
  label: string;
  description?: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col gap-[1px] w-full", props.className)}>
      <div className="py-3 pr-8 pl-1.5 gap-[4px] flex flex-col">
        <LabelText
          className={cn(
            "flex flex-row gap-1 items-center text-grey-300 text-[12px]"
          )}
          as="span"
        >
          {props.label}
        </LabelText>

        {props.description && (
          <LabelText as="span" className="text-grey-400 text-[12px]">
            {props.description}
          </LabelText>
        )}
      </div>

      <div className={cn("flex flex-row items-center w-full")}>
        <div
          className={cn(
            "w-full",
            props.disabled && "pointer-events-none opacity-50"
          )}
          tabIndex={-1}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

const InitialDataForm = (props: { step: string; onNext?: () => void }) => {
  const { userEntity, doUpdateUserEntity } = useUserContext();

  const [transitionStart, setTransitionStart] = useState(false);

  const formNotValid =
    isMissingOrEmpty(userEntity?.firstName) ||
    isMissingOrEmpty(userEntity?.lastName) ||
    isMissingOrEmpty(userEntity?.role);

  const doNext = () => {
    setTransitionStart(true);

    setTimeout(() => {
      props.onNext?.();
    }, 250);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (formNotValid) return;

        setTransitionStart(true);

        setTimeout(() => {
          props.onNext?.();
        }, 250);
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [formNotValid, props]);

  return (
    <div
      className={cn(
        "flex flex-col w-full h-full gap-2",
        "transition-all duration-500 ease-in-out",
        transitionStart && "opacity-0"
      )}
    >
      <Field label="First name">
        <Input
          type="text"
          placeholder={userEntity?.firstName ?? "Enter your first name"}
          value={userEntity?.firstName ?? ""}
          onValueChange={(val) => doUpdateUserEntity("firstName", val)}
        />
      </Field>

      <Field label="Last name">
        <Input
          type="text"
          placeholder={userEntity?.lastName ?? "Enter your last name"}
          value={userEntity?.lastName ?? ""}
          onValueChange={(val) => doUpdateUserEntity("lastName", val)}
        />
      </Field>

      <Field label="Role">
        <Input
          type="email"
          placeholder={userEntity?.role ?? "Enter the role at the company"}
          value={userEntity?.role ?? ""}
          onValueChange={(val) => doUpdateUserEntity("role", val)}
        />
      </Field>

      <Button
        variant="primary"
        onClick={doNext}
        className="mt-6 ml-auto"
        disabled={formNotValid}
      >
        Continue <ArrowRightIcon />
      </Button>
    </div>
  );
};

const LegalNameConfirmForm = (props: { onNext?: () => void }) => {
  const { userEntity, userId } = useUserContext();

  const [isLegalNameConfirmed, setIsLegalNameConfirmed] = useState<
    boolean | undefined
  >(
    isMissingOrEmpty(userEntity?.legalName)
      ? undefined
      : `${userEntity?.firstName} ${userEntity?.lastName}` ===
          userEntity?.legalName
  );

  const patchUserEntity = useMutation(patchUserEntityMutation)[1];
  const [actualLegalName, setActualLegalName] = useState<string>(
    userEntity?.legalName ?? ""
  );

  const doSubmit = () => {
    if (isLegalNameConfirmed === undefined) return;
    if (userId == null) return;

    if (isLegalNameConfirmed) {
      patchUserEntity({
        input: {
          userId: userId,
          data: {
            legalName: `${userEntity?.firstName} ${userEntity?.lastName}`,
          },
        },
      });
    }

    patchUserEntity({
      input: {
        userId: userId,
        data: {
          legalName: actualLegalName,
        },
      },
    });
    props.onNext?.();
  };

  const formNotValid =
    isLegalNameConfirmed === undefined ||
    (!isLegalNameConfirmed && isMissingOrEmpty(actualLegalName));

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (formNotValid) return;

        props.onNext?.();
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [formNotValid, props]);

  return (
    <div
      className={cn(
        "flex flex-col w-full h-full gap-2",
        "transition-all duration-500 ease-in-out"
      )}
    >
      <Field
        label={`Is ${userEntity?.firstName} ${userEntity?.lastName} your legal name?`}
        description="We need to know your legal name to make sure it is represented correctly in the application."
      >
        <div className="flex flex-row items-center gap-2">
          <RadioSelect
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            value={isLegalNameConfirmed}
            onChange={(val) => setIsLegalNameConfirmed(val as boolean)}
          />
        </div>
      </Field>

      <Field
        label="Correct Legal name"
        description="Use the name on your passport, or any other official government document."
        className={cn(
          (isLegalNameConfirmed || isLegalNameConfirmed === undefined) &&
            "hidden",
          isLegalNameConfirmed === false && "block",
          "transition-all duration-250 ease-in-out"
        )}
      >
        <Input
          type="text"
          placeholder="Enter your legal name"
          value={actualLegalName}
          onValueChange={(val) => setActualLegalName(val)}
        />
      </Field>

      <Button
        variant="primary"
        onClick={doSubmit}
        className="mt-6 ml-auto"
        disabled={formNotValid}
      >
        Continue <ArrowRightIcon />
      </Button>
    </div>
  );
};

const PhoneNumberForm = (props: { onNext?: () => void }) => {
  const { userEntity, doUpdateUserEntity } = useUserContext({
    requestPolicy: "cache-first",
  });

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        props.onNext?.();
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [props]);

  return (
    <div
      className={cn(
        "flex flex-col w-full h-full gap-2",
        "transition-all duration-500 ease-in-out"
      )}
    >
      <Field
        label="Phone number (optional)"
        description="We need to include your phone number on some government forms. If you are not a petitioner representative, you can skip this step."
      >
        <PhoneNumberInput
          value={userEntity?.phoneNumber as unknown as PhoneNumber}
          onValueChange={(val) => doUpdateUserEntity("phoneNumber", val)}
          placeholder="Enter your phone number"
        />
      </Field>

      <Button variant="primary" onClick={props.onNext} className="mt-6 ml-auto">
        Continue <ArrowRightIcon />
      </Button>
    </div>
  );
};

const VerifyInfo = (props: { onSubmit?: () => void }) => {
  const [step, setStep] = useState<
    "initial-data" | "legal-name-confirm" | "phone-number"
  >("initial-data");
  return (
    <FormCard className="w-2/5 h-fit flex flex-col pr-12 pt-8">
      <Heading align="left" weight="bold">
        Verify your information
      </Heading>

      {step === "initial-data" && (
        <InitialDataForm
          step={step}
          onNext={() => setStep("legal-name-confirm")}
        />
      )}

      {step === "legal-name-confirm" && (
        <LegalNameConfirmForm onNext={() => setStep("phone-number")} />
      )}

      {step === "phone-number" && <PhoneNumberForm onNext={props.onSubmit} />}
    </FormCard>
  );
};

const Complete = (props: { companyId: number; workspaceName: string }) => {
  const { setSelectedWorkspace } = useWorkspaces();
  const nav = useNavigate();

  const [transitionStart, setTransitionStart] = useState(false);
  const [h1Visible, setH1Visible] = useState(false);
  const [h2Visible, setH2Visible] = useState(false);
  const [hintVisible, setHintVisible] = useState(false);

  const [completeDone, setCompleteDone] = useState(false);

  const params = Route.useParams();
  const markComplete = useMutation(markInviteAsAccepted)[1];
  const client = useClient();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setH1Visible(true);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setH2Visible(true);
    }, 750);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHintVisible(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        if (completeDone) {
          nav({ to: "/home" });
          return;
        }
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [completeDone, nav]);

  useEffect(() => {
    if (params.inviteId == null) return;
    if (params.inviteId.trim() === "") return;
    if (isNaN(Number(params.inviteId))) return;

    setTimeout(async () => {
      const { error } = await markComplete({
        input: {
          inviteId: Number(params.inviteId),
        },
      });

      if (error != null) {
        console.error(error);
        return;
      }

      setTransitionStart(true);
      setCompleteDone(true);

      const res = await client.query(
        getOnboardingContext,
        {
          contextInput: {
            overrideUserId: null,
          },
        },
        {
          requestPolicy: "network-only",
        }
      );

      if (error != null) {
        console.error(error);
        return;
      }

      if (
        res.data?.context.workspaces == null ||
        res.data?.context.workspaces.length === 0
      ) {
        return;
      }

      const currentWorkspace = res.data.context.workspaces.find(
        (x) => x.id === `company-${props.companyId}`
      );

      setSelectedWorkspace(currentWorkspace);
    }, 2000);
  }, [
    params,
    markComplete,
    client,
    nav,
    setSelectedWorkspace,
    props.companyId,
  ]);

  return (
    <div>
      <div
        className={cn(
          "gap-2 flex flex-col items-center justify-center w-full",
          transitionStart && "opacity-0 hidden invisible",
          !transitionStart && "opacity-100",
          "transition-all duration-500"
        )}
      >
        <Heading
          align="center"
          weight="regular"
          className={cn(
            "transition-all duration-500 opacity-0",
            h1Visible && "opacity-100"
          )}
        >
          Almost done
        </Heading>

        <h2
          className={cn(
            "text-center text-sm text-grey-300 flex flex-row gap-2 items-center",
            "transition-all duration-500 opacity-0",
            h2Visible && "opacity-100"
          )}
        >
          Setting up your workspace <Spinner />
        </h2>
      </div>

      <div
        className={cn(
          "gap-2 flex flex-col items-center justify-center w-full",
          !transitionStart && "opacity-0",
          transitionStart && "opacity-100",
          "transition-all duration-500"
        )}
      >
        <Heading
          align="center"
          weight="regular"
          className={cn(
            "transition-all duration-500 opacity-0 -mt-6",
            h1Visible && "opacity-100"
          )}
        >
          Your workspace is ready
        </Heading>

        <div className="mt-2">
          <EnterToContinue hintVisible={hintVisible} />
        </div>
      </div>
    </div>
  );
};

const InvitePage = () => {
  const { inviteId } = Route.useParams();
  const nav = useNavigate();
  const [{ data, fetching }] = useQuery({
    query: getWorkspaceInvite,
    variables: { input: { inviteId: Number(inviteId) } },
    requestPolicy: "network-only",
  });

  const [page, setPage] = useState<"splash" | "verify-info" | "complete">(
    "splash"
  );

  return (
    <div
      className={cn(
        "flex flex-col w-full h-screen",
        "transition-all duration-500",
        "bg-[url(/backgrounds/login.webp)] bg-cover bg-center"
      )}
    >
      <div className="flex flex-col gap-4 py-6 px-8 w-full">
        <MarkRoundedSquareIcon />
      </div>

      <div className="w-full h-4/5 flex flex-col items-center justify-center">
        {fetching && <Spinner />}

        {!fetching && data?.workspaceInvite.id == null && (
          <FormCard className="w-2/5 h-fit flex flex-col pr-12 pt-8">
            <span className="text-lg text-grey-300">
              This invite does not exist or has expired. <br />
            </span>

            <Button variant="primary" onClick={() => nav({ to: "/home" })}>
              Return to Home <ArrowRightIcon />
            </Button>
          </FormCard>
        )}

        {data?.workspaceInvite.id != null && (
          <>
            {page === "splash" && (
              <Splash
                onNext={() => setPage("verify-info")}
                workspaceName={data.workspaceInvite.name}
              />
            )}
            {page === "verify-info" && (
              <VerifyInfo onSubmit={() => setPage("complete")} />
            )}
            {page === "complete" && (
              <Complete
                companyId={data.workspaceInvite.companyId}
                workspaceName={data.workspaceInvite.name}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const Route = createFileRoute("/_portal/workspaces/invite/$inviteId")({
  component: () => <InvitePage />,
});
