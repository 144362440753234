import { cn } from "@/lib/cn";
import { useEffect, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ButtonText, InputText } from "./typography";
import { ChevronDownIcon } from "@radix-ui/themes";
import { Command } from "cmdk";
import { CheckIcon } from "@radix-ui/react-icons";
import { isMissingOrEmpty } from "@/lib/utils";

export const Select = (props: {
  value?: string;
  onValueChange: (value?: string) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  options: { value: string; label: string }[];
  modal?: boolean;
  containerRef?: React.RefObject<HTMLElement | null>;
  hideSearch?: boolean;
}) => {
  const {
    value,
    onValueChange,
    disabled,
    className,
    hideSearch = false,
  } = props;

  const [open, setOpen] = useState(false);

  const buttonRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);

  useEffect(() => {
    const btnRef = buttonRef.current;
    if (btnRef == null) return;

    setButtonWidth(btnRef.offsetWidth);

    const handleResize = () => {
      setButtonWidth(btnRef.offsetWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={cn("flex flex-row gap-1 w-full z-10 rounded-md", className)}
      ref={buttonRef}
    >
      <Popover open={open} onOpenChange={setOpen} modal={props.modal}>
        <PopoverTrigger asChild disabled={disabled}>
          <button
            className={cn(
              "rounded-md resize-none px-3 py-2 w-full",
              "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
              "text-sm leading-[140%] font-normal",
              "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-500",
              "shadow-border focus:outline-none focus:shadow-border-active",
              "transition-all duration-200",
              props.className
            )}
          >
            <div className="flex flex-row items-center gap-1">
              {isMissingOrEmpty(value) && (
                <InputText className="text-grey-500 text-sm leading-[140%] font-normal">
                  {props.placeholder ?? "Select an option"}
                </InputText>
              )}
              {!isMissingOrEmpty(value) && (
                <InputText>
                  {
                    props.options.find((option) => option.value === value)
                      ?.label
                  }
                </InputText>
              )}
              <ChevronDownIcon className="ml-auto" />
            </div>
          </button>
        </PopoverTrigger>

        <PopoverContent
          className={cn("z-10 p-0 shadow-border overflow-scroll")}
          style={{
            width: buttonWidth,
          }}
          onWheel={(e) => e.stopPropagation()}
        >
          <Command>
            {!hideSearch && (
              <Command.Input
                className={cn(
                  "flex flex-row w-full items-center rounded-t-lg resize-none px-2 py-2",
                  "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%]",
                  "text-sm leading-[140%] font-normal",
                  "focus:outline-none border-b border-b-grey-700"
                )}
                placeholder="Search"
              />
            )}

            <Command.List
              className={cn("max-h-[200px] overflow-scroll w-full")}
            >
              {props.options.map((option) => {
                return (
                  <Command.Item
                    key={option.value}
                    value={option.value}
                    onSelect={(currentValue) => {
                      if (disabled) return;
                      onValueChange(currentValue);
                      setOpen(false);
                    }}
                    keywords={[option.value, option.label]}
                    className="px-2 py-1 flex flex-row gap-1 items-center rounded-md hover:bg-grey-700 data-[selected=true]:bg-grey-700 hover:cursor-pointer items-center w-full first:rounded-t-none last:rounded-b-none"
                  >
                    <ButtonText className="font-normal w-full items-center">
                      <div className="flex flex-row gap-2">{option.label}</div>

                      {option.value === value && (
                        <CheckIcon className="w-4 h-4 text-positive ml-auto" />
                      )}
                    </ButtonText>
                  </Command.Item>
                );
              })}
            </Command.List>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
